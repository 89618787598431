
export default function useInactiveDetector(timeout = 30000) {
  const timer = ref(null);
  const isInactive = ref(false);

  const startTimer = () => {
    timer.value = setTimeout(() => {
      isInactive.value = true;
    }, timeout);
  };

  const resetTimer = () => {
    clearTimeout(timer.value);
    isInactive.value = false;
    startTimer();
  };

  const stopTimer = () => {
    clearTimeout(timer.value);
    isInactive.value = false;
  };

  // Agregamos los listeners para detectar la interacción del usuario
  const addEventListeners = () => {
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keydown', resetTimer);
  };

  // Eliminamos los listeners cuando el componente se destruye
  const removeEventListeners = () => {
    window.removeEventListener('mousemove', resetTimer);
    window.removeEventListener('keydown', resetTimer);
    stopTimer();
  };

  return {
    startTimer,
    resetTimer,
    stopTimer,
    addEventListeners,
    removeEventListeners,
    isInactive
  };
}
