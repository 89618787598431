<!-- Name -->
<script lang="ts">
export default {
  name: 'modal-custom'
}
</script>

<script lang="ts" setup>
import { useModalStore } from '~/store/modal';

const modalStore = useModalStore();
const { hideModal } = modalStore;
const { isMobileSize } = useDeviceFunctions();

const closeModal = (ev) => {
  if (ev.target.classList.contains("modal-wrapper") || ev.target.classList.contains("btn-close")) {
    hideModal()
  }
}

const isModalShow = computed(() => {
  return modalStore.modal.showModal;
});

const getContentClass = computed(() => {
  return !isMobileSize() ? 'modal-custom__content' : 'modal-custom__content-mobile';
});


const isContentFull = computed(() => {
  return modalStore.modal?.size && modalStore.modal.size === 'full';
});

const isContentFullMobile = computed(() => {
  return modalStore.modal?.size && modalStore.modal.size === 'full-mobile';
});

const modalProps = computed(() => {
  return {
    model: modalStore.modal.props
  };
});


</script>

<template lang="pug">
.modal-custom.modal-wrapper(
  :class="{ 'show': isModalShow }",
  @click="closeModal($event)")
  div(
    :class="[getContentClass, modalStore.modal.class, { show: isModalShow, 'full': isContentFull, 'full-mobile': isContentFullMobile}]"
  )
    ClientOnly
      component(:is="modalStore.modal.component" v-bind="modalProps")
</template>

<style lang="scss" scoped>
@import 'index';
</style>