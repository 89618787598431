<!-- Name -->
<script lang="ts">
export default {
  name: 'app-go-top'
}
</script>

<script lang="ts" setup>

const goToTop = () => {
  window.scrollTo(0, 0);
};

</script>

<template lang="pug">
button.app-go-top(@click="goToTop")
  span.icon-arrow-right-long
  span SUBIR
</template>

<style lang="scss">
@import 'index';
</style>