
export default function useDetectOutsideClick(component, callback) {
    if (!component) return;
    const listener = (event) => {


        let isCookeboot = false;
        let target = event?.target;
        const els = [];
        while (target) {
            els.unshift(target);
            if(target.id == 'CybotCookiebotDialog'){
                isCookeboot = true;
            }
            target = target.parentNode;
        }

        if(isCookeboot){
            return;
        }


        if (event.target !== component.value && event.composedPath().includes(component.value)) {
            return;
        }
        if (typeof callback === 'function') {
            callback();
        }
    };

    onMounted(() => { 
        window.addEventListener('click', listener); 
    });
    
    onBeforeUnmount(() => {
        window.removeEventListener('click', listener);
    });

    return {listener};
}